import { PAGE_TYPE } from 'services/routing/PATH_PARAM';

/**
 * Данный класс работает с фронтовым адресом, как напрямую со строкой, так и со своим фронтовым объектом адреса LocationSearchObject
 * @see LocationSearchObject
 */
class NavigateFrontendUtils {
  /** Если мы пытаемся в адрес проставить такую страницу, которая там уже и так стоит (это может случиться если до этого страницы мы загружали через кнопку loadMore), то нужно передать кодовый параметр, что тригернёт загрузку данных насильно. Чтобы такого костыля не было, нужно всегда писать в адрес страницу, но тогда придётся передавать что-то при loadMore, что остановит DataWrapper от загрузки данных... */
  public static readonly FORCE_RELOAD = 'forceReload';

  // Работа с LocationSearchObject -----------------------------------------------------------------

  /** @deprecated Создавать руками объект с типом `LocationSearchObject`, его свойства создавать с помощью метода createLocationSearchParam */
  public createNewLocationSearchObject = () => {};

  public static getLocationSearchObjectFromCurrentLocation = <T extends LocationSearchObject = Record<string, LocationSearchParam | null | undefined>>(
    options?: LocationSearchObjectOptions
  ): LocationSearchObject<T> => this.getLocationSearchObjectFromQueryStr<T>(window.location.search, options);

  public static getPathnameAndSearchObjectFromLocation = (fullLocationString: string): [string, LocationSearchObject] => {
    let params: LocationSearchObject;
    let pathname: string;
    const indexOfParams = fullLocationString.indexOf('?');
    if (indexOfParams > 0) {
      pathname = fullLocationString.slice(0, indexOfParams);
      params = this.getLocationSearchObjectFromQueryStr(fullLocationString.slice(indexOfParams + 1));
    } else {
      pathname = fullLocationString;
      params = {};
    }
    return [pathname, params];
  };

  public static getLocationSearchObjectFromQueryStr = <T extends LocationSearchObject = LocationSearchObject>(
    searchString: string,
    options?: LocationSearchObjectOptions
  ): T => {
    if (options?.excludeAll) {
      return {} as T;
    }

    const searchObject = Object.fromEntries(new URLSearchParams(searchString).entries());
    const locationSearchParams = Object.keys(searchObject).reduce((acc, key) => {
      const queryStr = searchObject[key];
      if (queryStr) {
        acc[key] = this.parseLocationSearchParamFromString(queryStr);
      }
      return acc;
    }, {} as LocationSearchObject);

    if (options?.exclude) {
      options.exclude.forEach((key) => {
        delete locationSearchParams[key];
      });
    }

    return locationSearchParams as T;
  };

  /** Делает из PageEntityFilter с его вложенностью обычный LocationSearchObject (вложенность переписывает на один ключ с точками, например department.parent.id); также дописывает page = 1 (иначе во фронте в адресе останется старое значение page)
   * @deprecated
   */
  public static preparePageEntityFilterForRequest = <M extends Model>(filter: PageEntityFilterParams<M>): LocationSearchObject => {
    const keys = Object.keys(filter) as (keyof typeof filter)[];

    const flatFilter = keys.reduce((acc, key) => {
      const innerKeys = [key] as string[];
      const [mappedInnerKeys, finalValue] = this.pageEntityFilterFlatter(innerKeys, filter);
      acc[mappedInnerKeys.join('.')] = finalValue;
      return acc;
    }, {} as LocationSearchObject);

    flatFilter.page = this.createLocationSearchParam('1');
    return flatFilter;
  };

  /** Мутирует передаваемый объект */
  public static resetLSOPage = (locationSearchObject: LocationSearchObject) => {
    locationSearchObject.page = this.createLocationSearchParam('1')
    return locationSearchObject
  }

  public static createURLWithSearchQuery = (pathname: string, urlSearchObject?: LocationSearchObject): string => {
    if (!urlSearchObject) {
      return pathname;
    }

    const paramKeys = Object.keys(urlSearchObject);
    if (!paramKeys.length) {
      return pathname;
    }

    const paramsStr = NavigateFrontendUtils.createSearchStrFromLocationSearchObject(urlSearchObject);
    const paramsSymbol = pathname.includes('?') ? '&' : '?';
    return `${pathname}${paramsSymbol}${paramsStr}`;
  };

  /** @returns URL query string, возвращает без ? в начале */
  public static createSearchStrFromLocationSearchObject = (urlSearchObject: LocationSearchObject): string => {
    const paramKeys = Object.keys(urlSearchObject);
    return `${paramKeys
      .sort() // Должно всегда быть отсортировано, чтобы работал хеш в хранилище (он сверяет адрес запроса на строгое равно и не делает лишних запросов, если сущность не помечена устаревшей)
      .reduce((acc, key) => {
        const urlSearchParam = urlSearchObject[key];
        if (urlSearchParam?.isNotEmpty) {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(`${urlSearchParam.rawValue}`)}`);
        }
        return acc;
      }, [] as string[])
      .join('&')}`;
  };

  /** Удаляет существующий search у адреса и добавляет ему текущий */
  public static createURLWithCurrentSearchQuery = (newPathname: string): string => {
    [newPathname] = newPathname.split('?');
    return `${newPathname}${window.location.search}`;
  };

  public static getDefaultSortSearchParams = (
    sortFieldName: string = 'name',
    sortOrder: NavigateFrontendUtils.SORT_ORDER = NavigateFrontendUtils.SORT_ORDER.asc
  ) => {
    const filter = {
      sortOrder: NavigateFrontendUtils.createLocationSearchParam(sortOrder),
      sortedBy: NavigateFrontendUtils.createLocationSearchParam(sortFieldName as string), // Тут мы вписываем во фронтовый объект фронтовое название поля
    };
    return filter;
  }

  // TODO зачем второй параметр
  /** Дописывает новые параметры к текущему search query, существующие перезаписывает */
  public static addSearchQueryToCurrentLocation = (newLocationSearchObject: LocationSearchObject, newPathname?: string): string => {
    const pathname = newPathname || window.location.pathname;
    const currentParams = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();
    const updatedParams = { ...currentParams, ...newLocationSearchObject };
    // Нам нужно иметь всегда отсортированную строку, чтобы отслеживать, изменилась ли она
    return NavigateFrontendUtils.createURLWithSearchQuery(pathname, updatedParams);
  };

  /** Устанавливает конкретные search query у текущего pathname */
  public static setSearchQueryToCurrentPathname = (urlSearchObject: LocationSearchObject): string => {
    return NavigateFrontendUtils.createURLWithSearchQuery(window.location.pathname, urlSearchObject);
  };

  /** Удаляет конкретные части search query из текущего адреса */
  public static deleteSpecificSearchQueryParamFromCurrentLocation = (queryParams: string[]): string => {
    const urlSearchObject = NavigateFrontendUtils.getLocationSearchObjectFromCurrentLocation();
    queryParams.forEach((param) => {
      delete urlSearchObject[param];
    });
    // Нам нужно иметь всегда отсортированную строку, чтобы отслеживать, изменилась ли она
    return NavigateFrontendUtils.createURLWithSearchQuery(window.location.pathname, urlSearchObject);
  };

  // Работа с LocationSearchParam -----------------------------------------------------------------------

  // Перегрузка: если передаём массив, то получаем LocationSearchParam<'array'> иначе LocationSearchParam<'single'>
  /** Создаёт объект, содержащий все сведения о типе фильтрации по одному параметру */
  public static createLocationSearchParam<T extends string[] | number[] | boolean[]>(
    value: T,
    matchType?: LocationSearchParam['matchType']
  ): LocationSearchParam<'array', `${T[0]}`>;
  public static createLocationSearchParam<T extends string | number | boolean>(
    value: T,
    matchType?: LocationSearchParam['matchType']
  ): LocationSearchParam<'single', `${T}`>;
  public static createLocationSearchParam(
    value: string | number | boolean | string[] | number[] | boolean[],
    matchType: LocationSearchParam['matchType'] = 'eq'
  ): LocationSearchParam {
    const isArray = Array.isArray(value);
    const matchTypeString = matchType === 'eq' ? '' : ';' + matchType;
    if (isArray || matchType === 'in') {
      const values = isArray ? value.map((v) => v.toString()) : value ? [value.toString()] : [];
      const isNotEmpty = values.length > 0;
      return {
        matchType,
        values,
        valueType: 'array',
        // запятая вначале показывает, что работаем с массивом
        rawValue: ',' + values.join(',') + matchTypeString,
        isNotEmpty,
      };
    } else {
      const finalValue = value.toString();
      const isNotEmpty = Boolean(finalValue);
      return {
        matchType,
        values: [finalValue],
        valueType: 'single',
        value: finalValue,
        rawValue: finalValue + matchTypeString,
        isNotEmpty,
      };
    }
  }

  // Вроде не нужно больше
  // public static urlSearchQueryToViewFilters = <T extends Model, DTO extends EntityDTOResponse>(
  //   queryStr: string,
  //   mapper: IMapper<T, DTO>
  // ): ViewFilterDTO[] => {
  //   queryStr = queryStr.replace(/^\?/, '');
  //   const viewFilters: ViewFilterDTO[] = [];
  //   const keyValueArr = queryStr.split('&');
  //   keyValueArr.forEach((kvStr) => {
  //     const [key, value] = kvStr.split('=');
  //     if (!key || !value) {
  //       return;
  //     }

  //     // TODO нет нормального маппинга, когда передаётся вложенное поле, сейчас мапится только первое
  //     const keys = key.split('.');
  //     const firstMappedKey = mapper.getDBResponseFieldName(keys[0] as keyof Model, true);

  //     // Работаем только если что-то вернулось из мапера, но т.к. сейчас мапится только первое поле, то проверяем только его
  //     if (firstMappedKey) {
  //       keys[0] = firstMappedKey;
  //       const mappedKey = keys.join('.');
  //       viewFilters.push({
  //         technicalName: mappedKey as string,
  //         value,
  //       });
  //     }
  //   });
  //   return viewFilters;
  // };

  // Мелкие публичные утилиты ---------------------------------------------------------------------------

  /** Проверяет, что переданный параметр адресной строки это id (в текущей версии все id это uuid) (UPD теперь ещё и монговский) */
  public static isIdExist = (param: string | PAGE_TYPE) => {
    // Если полноценная регулярка для проверки uuid, но в текущей реализации это избыточно, т.к. все остальные варианты param значительно короче по длине (енам PAGE_TYPE) и можно проверять просто её
    // /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    return param.length > 10;
  };

  /** Проверяет, что переданный параметр адресной строки это зарезервированное слово list */
  public static isList = (param: string | PAGE_TYPE) => {
    return param === PAGE_TYPE.list;
  };

  // Приватные методы -----------------------------------------------------------------------------------

  /** @see preparePageEntityFilterForRequest */
  private static pageEntityFilterFlatter = (
    keys: string[],
    filter: PageEntityFilterParams<any | any[]>
  ): [string[], LocationSearchParam | null] => {
    const currentKey = keys[keys.length - 1];
    const currentValue = filter[currentKey];
    if (!currentValue) return [keys, null];

    const possibleLocationSearchParam = currentValue as Partial<LocationSearchParam>;
    const { matchType, values } = possibleLocationSearchParam;

    if (matchType && values) {
      return values.length > 0 ? [keys, possibleLocationSearchParam as LocationSearchParam] : [keys, null];
    } else {
      const possibleInnerPageEntityFilterArray = currentValue as PageEntityFilterParams<any>[];
      const possibleInnerPageEntityFilter = Array.isArray(possibleInnerPageEntityFilterArray)
        ? possibleInnerPageEntityFilterArray[0]
        : (currentValue as PageEntityFilterParams<any>);
      const possibleInnerPageEntityFilterKeys = Object.keys(possibleInnerPageEntityFilter);
      if (possibleInnerPageEntityFilterKeys.length) {
        keys.push(possibleInnerPageEntityFilterKeys[0]);
        return this.pageEntityFilterFlatter(keys, possibleInnerPageEntityFilter);
      }
    }
    return [keys, null];
  };

  private static parseLocationSearchParamFromString = (rawValue: string): LocationSearchParam => {
    let [string, matchType] = rawValue.split(';');
    // Если начитается с запятой то это явный способ указать, что работаем с массивом
    let isArray = false;
    if (string[0] === ',') {
      string = string.slice(1);
      isArray = true;
    }
    const values = string.split(',');
    if (values.length > 1) {
      isArray = true;
    }
    const isNotEmpty = Boolean(string);
    if (isArray) {
      return {
        matchType: (matchType as LocationSearchParam['matchType']) || 'eq',
        values,
        value: values[0],
        valueType: 'array',
        rawValue,
        isNotEmpty,
      };
    } else {
      return {
        matchType: (matchType as LocationSearchParam['matchType']) || 'eq',
        values,
        valueType: 'single',
        value: values[0],
        rawValue,
        isNotEmpty,
      };
    }
  };
}

namespace NavigateFrontendUtils {
  export enum SORT_ORDER {
    asc = 'ASC',
    desc = 'DESC',
  }
}

export default NavigateFrontendUtils;
