export enum ACTION_FIELD_TYPE {
  date = 'Date',
  text = 'Text',
  textarea = 'Textarea',
  file = 'File',
  switch = 'Switch',
  checkbox = 'Checkbox',
  select = 'Select',
  radioGroup = 'Radiogroup',
  number = 'Nubmer',
  // спец тип для текста
  textField = 'TextField',
}

export enum VXLABEL_TYPE {
  text = 'TEXT',
  resource = 'RESOURCE',
}
