import * as Yup from 'yup';
import ILanguageService from 'services/language/ILanguageService';
import { MixedSchema } from 'yup/lib/mixed';
import IFileService from 'services/file/IFileService';
import { ACTION_FIELD_TYPE, VXLABEL_TYPE } from 'typings/models/serverAction.enum';
import EntityUtils from 'utils/Entity';
import DateWithTimezone from 'utils/implementations/DateWithTimezone';
import FunctionUtils from 'utils/Function';

export default class ServerActionUtils {
  /** Возвращает законченную схему для динамических полей, пригодную для формика */
  public static getValidationScheme = (
    fields: ActionField[],
    languageService: ILanguageService,
    /** На фронте есть кейсы где при работе с DF валидацию надо показать, но не запрещать сабмитить */
    disableValidationSubmitBlock?: boolean
  ): Yup.ObjectSchema<any> => {
    const subScheme = this.getValidationSubScheme(fields, languageService, disableValidationSubmitBlock);
    return Yup.object().shape(subScheme);
  };

  /** Возвращает схему для динамических полей, которую потребуется обернуть в Yup.object().shape(); сделано, если нужно добавить ещё каких-то полей */
  public static getValidationSubScheme = (
    fields: ActionField[],
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    return fields.reduce(
      (acc, c) => ({ ...acc, ...this.getFieldValidationSubScheme(c, languageService, disableValidationSubmitBlock) }),
      {} as Record<string, MixedSchema<any, any, any>>
    );
  };

  private static getFieldValidationSubScheme = (
    field: ActionField,
    languageService: ILanguageService,
    disableValidationSubmitBlock?: boolean
  ): Record<string, MixedSchema<any, any, any>> => {
    const subScheme: Record<string, MixedSchema<any, any, any>> = {};
    const fieldIsRequiredText = languageService.translate('errors.fieldIsRequired');
    const maximumArrayLengthExceededText = languageService.translate('errors.maximumArrayLengthExceeded');
    switch (field.fieldTypeId) {
      case ACTION_FIELD_TYPE.file: {
        // Нужно чекать два поля - старые файлы и новые
        subScheme[field.name] = Yup.array();
        subScheme[field.name + '_old'] = Yup.array();

        if (!field.metadata.multiple) {
          subScheme[field.name + '_old'] = (subScheme[field.name + '_old'] as ReturnType<typeof Yup.array>).test({
            message: maximumArrayLengthExceededText + ' (1)',
            test: (v, context) => v!.length + context.parent[field.name]!.length <= 1,
          });
        }

        if (field.metadata.required && !disableValidationSubmitBlock) {
          subScheme[field.name + '_old'] = (subScheme[field.name + '_old'] as ReturnType<typeof Yup.array>).test({
            message: fieldIsRequiredText,
            test: (v, context) => Boolean(v!.length + context.parent[field.name]!.length),
          });
        }
        break;
      }
      // TODO нет учета multiple
      case ACTION_FIELD_TYPE.number:
      case ACTION_FIELD_TYPE.textarea:
      case ACTION_FIELD_TYPE.text: {
        subScheme[field.name] = Yup.string();
        break;
      }
      // TODO нет учета multiple
      case ACTION_FIELD_TYPE.checkbox:
      case ACTION_FIELD_TYPE.switch: {
        subScheme[field.name] = Yup.boolean();
        break;
      }
      case ACTION_FIELD_TYPE.date: {
        // Спец поля
        return subScheme;
      }
      case ACTION_FIELD_TYPE.radioGroup:
      case ACTION_FIELD_TYPE.select: {
        if (field.metadata.multiple) {
          if (field.metadata.required) subScheme[field.name] = Yup.array().min(1, fieldIsRequiredText);
          else subScheme[field.name] = Yup.array();
        } else {
          subScheme[field.name] = Yup.mixed();
        }
        break;
      }
      default: {
        subScheme[field.name] = Yup.mixed();
      }
    }

    if (field.name && !disableValidationSubmitBlock) {
      subScheme[field.name] = subScheme[field.name].required(fieldIsRequiredText);
    }
    return subScheme;
  };

  public static getInitialLabelValue = (label: ServerAction.VxLabel) => {
    return label.type === VXLABEL_TYPE.text ? label.value : '';
  };

  public static mergeMetadata = (staticMetadata: ServerAction, dynamicMetadata: ServerActionDynamicMetadata): ServerAction => {
    const serverAction = {
      ...staticMetadata,
      fields: staticMetadata.fields.map((field) => ({
        ...field,
        value: dynamicMetadata.fieldValues[field.name],
        variants: dynamicMetadata.dynamicMetadata[field.name]?.length ? dynamicMetadata.dynamicMetadata[field.name] : field.variants,
      })),
    };
    return serverAction;
  };

  /** @param configs если требуется отобразить все поля из конфигов, но со значениями из заполненных */
  public static getInitialState = (fields: ActionField[]): Record<string, any> => {
    return fields.reduce((acc, field) => this.setFieldInitialValue(field, acc), {} as Record<string, any>);
  };

  /** @returns мутирует входящий объект, возвращает его же */
  public static setFieldInitialValue = (field: ActionField, initialState: Record<string, any>): Record<string, any> => {
    switch (field.fieldTypeId) {
      case ACTION_FIELD_TYPE.file: {
        initialState[field.name + '_old'] = field.value;
        initialState[field.name] = [] as IFileService.FileForUploaderUnknownData[];
        break;
      }
      // TODO нет учета multiple
      case ACTION_FIELD_TYPE.number:
      case ACTION_FIELD_TYPE.textarea:
      case ACTION_FIELD_TYPE.text: {
        initialState[field.name] = field.value ?? '';
        break;
      }
      case ACTION_FIELD_TYPE.checkbox:
      case ACTION_FIELD_TYPE.switch: {
        initialState[field.name] = field.value ?? false;
        break;
      }
      case ACTION_FIELD_TYPE.radioGroup:
      case ACTION_FIELD_TYPE.select: {
        initialState[field.name] = field.variants.find((v) => v.name === field.value) ?? null;
        break;
      }
      case ACTION_FIELD_TYPE.date: {
        // Спец поля
        return initialState;
      }
      default: {
        // FunctionUtils.exhaustiveCheck(field.type);
      }
    }
    return initialState;
  };

  public static editActionFieldStateToRequestDTO = async (
    actionField: ActionField,
    fileService: IFileService,
    formValues: Record<string, any>
  ): Promise<string> => {
    let value: any = '';
    const valuesRaw: any = formValues[actionField.name];
    switch (actionField.fieldTypeId) {
      // TODO нет учёта multiple
      case ACTION_FIELD_TYPE.switch:
      case ACTION_FIELD_TYPE.checkbox: {
        value = valuesRaw;
        break;
      }
      case ACTION_FIELD_TYPE.number:
      case ACTION_FIELD_TYPE.textarea:
      case ACTION_FIELD_TYPE.text: {
        value = valuesRaw;
        break;
      }
      case ACTION_FIELD_TYPE.file: {
        const initialFiles = actionField.value;
        const oldFilesUpdated = formValues[actionField.name + '_old'] as IFileService.File_[];
        const filesToRemove = EntityUtils.findRemoved(initialFiles, oldFilesUpdated);
        const newFileRequests = (valuesRaw as File[]).length ? (valuesRaw as File[]).map((f) => fileService.uploadOneFile(f)) : [];
        const newFiles = await Promise.all(newFileRequests);
        value = newFiles.map((f) => f.id).concat(oldFilesUpdated.map((f) => f.id));
        await fileService.deleteMany(filesToRemove.map((f) => f.id)).catch((error) => console.error(error?.message)); // Сейчас нет обработчика, но ошибку возвращать нельзя
        break;
      }
      case ACTION_FIELD_TYPE.date: {
        value = valuesRaw.map((date: DateWithTimezone) => {
          return date.toISOString();
        });

        break;
      }
      case ACTION_FIELD_TYPE.radioGroup:
      case ACTION_FIELD_TYPE.select: {
        if (!actionField.metadata.multiple) {
          value = valuesRaw.id;
          break;
        }
        value = (valuesRaw as EntityWithName[]).map((v) => v.id);
        break;
      }
      // никак не обрабатывается, так как по сути являетс просто информативным текстом
      case ACTION_FIELD_TYPE.textField:
        break;
      default: {
        FunctionUtils.exhaustiveCheck(actionField.fieldTypeId);
      }
    }

    return Array.isArray(value) ? JSON.stringify(value) : value ?? '';
  };
}
